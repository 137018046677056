import React from 'react'

import Title from '@components/title'

import bgApartmentTop from './top-banner.svg'
import './styles.css'

const TopBanner = ({ title, subtitle }) => (
  <div className="banner-bb">
    <div className="mw1700 center mb--03">
      <div
        className="contain bg-bottom tc pt5 pb-18 ph3"
        style={{ backgroundImage: `url(${bgApartmentTop})` }}
      >
        <Title
          heading="h5"
          noUnderline
          fontWeight="normal"
          className="ttu mb4 tracked-mega"
        >
          {title}
        </Title>

        <Title
          heading="h1"
          noUnderline
          fontWeight="bold"
          className="ttu fw7 mb3"
        >
          {subtitle}
        </Title>
      </div>
    </div>
  </div>
)

export default TopBanner
