import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './styles.css'

const Title = ({
  heading = 'h2',
  children,
  noUnderline,
  className,
  textCenter,
  fontWeight = 'semi-bold',
  color,
  id
}) => {
  const Heading = heading
  return (
    <Heading
      className={classNames(
        {
          'josefin relative mv0 lh-solid': true,
          'title-line pb4': !noUnderline,
          'title-line--center': textCenter,
          navy: !color,
          fw7: fontWeight === 'bold',
          fw6: fontWeight === 'semi-bold',
          fw4: fontWeight === 'normal',
          fw3: fontWeight === 'light',
          tc: textCenter,
          'f4-sm f2-m f1-l': heading === 'h1',
          'f5-sm f4-m f3-l': heading === 'h2',
          'f6-sm f5-m f4-l': heading === 'h3',
          'f7-sm f6-m f5-l': heading === 'h4',
          'f8-sm f7-m f6-l': heading === 'h5',
          'f9-sm f8-m f7-l': heading === 'h6'
        },
        className,
        color
      )}
      id={id}
    >
      {children}
    </Heading>
  )
}

Title.propTypes = {
  children: PropTypes.any,
  heading: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  noUnderline: PropTypes.bool,
  className: PropTypes.string
}

export default Title
