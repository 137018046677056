import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from '@components/layout'
import Title from '@components/title'
import TextWrapper from '@page/asukkaalle/text-wrapper'
import TopBanner from '@components/top-banner'

const PrivacyPolicyPage = ({ uri }) => {
  return (
    <Layout currentLocation={uri}>
      <Helmet>
        <title>Tietosuojaseloste | Tuulia Kodit</title>
      </Helmet>
      <TopBanner title="Tuulia Kodit" subtitle="tietosuojaseloste" />

      <section className="section">
        <div className="container">
          <Title heading="h3" className="mb4">
            Evästeet
          </Title>
          <TextWrapper className="f9-sm f8-m f7-l mv0">
            Eväste eli cookie on pieni tiedosto, jolla verkkopalvelu tunnistaa
            tietokoneesi, sekä kerää ja vaihtaa tietoa. Valtaosa verkkosivuista
            käyttää evästeitä. Voit estää evästeiden käytön selaimesi
            asetuksista, mutta tällöin jotkin verkkosivut eivät toimi oikein.{' '}
            <br />
            <br />
            Me käytämme evästeitä sujuvoittaaksemme sivustomme käyttöä sekä
            kehittääksemme verkkosisältöämme. Keräämme tilastoja kävijöistämme
            sekä heidän tavastaan käyttää palveluamme. Evästetietojen
            säilytysaika vaihtelee: istuntokohtaiset evästeet poistetaan selain
            suljettaessa, muilla evästeillä voimassaoloaika vaihtelee joistakin
            kuukausista muutamiin vuosiin. Sivustollamme voi olla myös
            kolmansien osapuolten (mm. Google Analytics, Facebook, YouTube)
            evästeitä.
          </TextWrapper>
          <Title heading="h3" className="mb4 mt5">
            Rekisterinpitäjä
          </Title>
          <TextWrapper className="f9-sm f8-m f7-l mv0">
            <p>Nimi: Tuulia Capital Oy</p>
            <p>Osoite: Rantaväentie 81, 18200 Heinola</p>
            <p>
              Yhteystiedot:{' '}
              <a href="_mailto: vuokrakodit@tuuliacapital.fi">
                vuokrakodit@tuuliacapital.fi
              </a>
              , puh. +358408340117
            </p>
          </TextWrapper>
          <Title heading="h3" className="mb4 mt5">
            Rekisterin nimi
          </Title>
          <TextWrapper className="f9-sm f8-m f7-l mv0">
            Tuulia Kodit asiakasrekisteri
          </TextWrapper>
          <Title heading="h3" className="mb4 mt5">
            Henkilötietojen käsittelyn tarkoitus
          </Title>
          <TextWrapper className="f9-sm f8-m f7-l mv0">
            Henkilötietojen käsittelyn tarkoituksena on varmistaa Tuulia Capital
            Oy:n kyky palvella tehokkaasti yrityksen olemassa olevia asiakkaita,
            suorittaa vuokralaisvalintaa sekä huolehtia viestinnästä
            vuokrahakemuksen jättäneiden potentiaalisten asiakkaiden kanssa.{' '}
          </TextWrapper>
          <Title heading="h3" className="mb4 mt5">
            Käsittelyn oikeusperusteet
          </Title>
          <TextWrapper className="f9-sm f8-m f7-l mv0">
            Henkilötietojen käsittelyn oikeusperusteita ovat seuraavat
            henkilötietolain ja EU:n yleisen tietosuoja-asetuksen mukaiset
            perusteet:
            <ul className="arrow-list arrow-list--narrow">
              <li>Rekisteröidyn yksiselitteisesti antama suostumus</li>
              <li>
                {' '}
                Rekisteröidyn toimeksianto tai sellaisen sopimuksen
                täytäntöönpaneminen, jossa rekisteröity on osallisena, taikka
                sopimusta edeltävien toimenpiteiden toteuttaminen rekisteröidyn
                pyynnöstä
              </li>
              <li>
                Rekisteröidyn asiakas- tai palvelussuhteen, jäsenyyden tai muun
                niihin verrattavaan suhteeseen perustuva asiallinen yhteys
                rekisterinpitäjän toimintaan
              </li>
            </ul>
          </TextWrapper>
          <Title heading="h3" className="mb4 mt5">
            Rekisterin tietosisältö
          </Title>
          <TextWrapper className="f9-sm f8-m f7-l mv0">
            Rekisteri sisältää tiedot asiakassuhteessa olevista asiakkaista,
            sekä potentiaalisista asiakkaista jotka ovat luovuttaneet
            yhteystietonsa sekä mahdollisesti muita vuokralaisvalinnan kannalta
            oleellisia tietoja rekisterinpitäjälle asuntojen vuokraamista tai
            myymistä varten. Rekisteri sisältää seuraavat tiedot:
            <ul className="arrow-list arrow-list--narrow">
              <li>Perustiedot, sisältäen etu- ja sukunimen</li>
              <li>Osoitetiedot, sisältäen postiosoitteen</li>
              <li>
                Yhteystiedot, sisältäen sähköpostiosoitteen ja puhelinnumeron
              </li>
              <li>
                Taloudelliseen asemaan liittyvät tiedot (ammatti, työnantaja,
                tulotaso ja tieto mahdollisista maksuhäiriömerkinnöistä)
              </li>
              <li>
                Muut taustatiedot ja lisätiedot (nykyinen asumismuoto, syy
                muuttoon, tieto hakijan lemmikkieläimistä, tieto tupakoinnista)
              </li>
              <li>
                Tieto mahdollisista markkinointikielloista ja -rajoituksista
              </li>
            </ul>
          </TextWrapper>
          <Title heading="h3" className="mb4 mt5">
            Säännönmukaiset tietolähteet
          </Title>
          <TextWrapper className="f9-sm f8-m f7-l mv0">
            Rekisteriin merkityt tiedot saadaan rekisteröidyltä itseltään.
            Maksuhäiriömerkintöjen osalta saatu tieto voidaan tarkistaa
            luottotietorekisteriä ylläpitäviltä palveluntarjoajilta, mikäli
            tietojen tarkastamiseen on syntynyt oikeudellinen peruste.
          </TextWrapper>
          <Title heading="h3" className="mb4 mt5">
            Henkilötietojen säilytysaika
          </Title>
          <TextWrapper className="f9-sm f8-m f7-l mv0">
            Rekisteriin tallennetut tiedot säilytetään 1kk vuokralaisvalinnan
            tultua suoritetuksi, tai niin kauan kuin tämä on asiakassuhteen
            ylläpitämisen ja rekisteröidyn etujen turvaamisen kannalta
            välttämätöntä.
          </TextWrapper>
          <Title heading="h3" className="mb4 mt5">
            Tietojen säännönmukaiset luovutukset
          </Title>
          <TextWrapper className="f9-sm f8-m f7-l mv0">
            Tietoja ei luovuteta, poislukien tietojen luovuttaminen
            lakisääteisesti viranomaiskäyttöön.
          </TextWrapper>
          <Title heading="h3" className="mb4 mt5">
            Tietojen siirto EU:n tai ETA:n ulkopuolelle
          </Title>
          <TextWrapper className="f9-sm f8-m f7-l mv0">
            Tietoja ei siirretä.
          </TextWrapper>
          <Title heading="h3" className="mb4 mt5">
            Rekisterin suojauksen periaatteet
          </Title>
          <TextWrapper className="f9-sm f8-m f7-l mv0">
            <p>
              Henkilötietoja sisältäviä aineistoja säilytetään valvotuissa tai
              lukituissa tiloissa, joihin on pääsy ainoastaan nimetyillä ja
              tehtäviensä vuoksi pääsyyn valtuutetuilla henkilöillä. Manuaalista
              aineistoa kerätään ainoastaan tietojen edelleen rekisteriin
              merkitsemistä varten, eikä varsinaista rekisteriä ylläpidetä
              manuaalisessa muodossa. Kertynyt manuaalinen aineisto hävitetään
              tietojen tultua edelleen kirjatuiksi.
            </p>
            <p>
              Henkilötietorekisteriä säilytetään tallennusmedioilla, joita
              säilytetään lukituissa tiloissa. Käytettävät tietojärjestelmät
              edellyttävät tunnistautumista ja ovat salasanalla suojattuja.
              Käytettävät tietojärjestelmät on suojattu palomuurilla ja muilla
              tarvittavilla teknisillä suojaustoimenpiteillä.
            </p>

            <p>
              Henkilötiedot säilytetään luottamuksellisina. Rekisterin
              käyttöoikeus on vain niillä Tuulia Capital Oy:n palveluksessa
              olevilla henkilöillä, joiden toimenkuva edellyttää ko. tietojen
              käsittelyä.
            </p>
          </TextWrapper>
          <Title heading="h3" className="mb4 mt5">
            Rekisteröidyn oikeudet
          </Title>
          <TextWrapper className="f9-sm f8-m f7-l mv0">
            <p>
              Rekisteröity voi pyytää rekisterinpitäjältä koosteen häntä itseään
              koskevista henkilötiedoista, pyytää kyseisten tietojen
              oikaisemista tai poistamista sekä käsittelyn rajoittamista.
              Lisäksi rekisteröity voi vastustaa käsittelyä sekä pyytää tietojen
              siirtämistä järjestelmästä toiseen. Rekisterinpitäjä käsittelee
              rekisteröidyn pyynnön ja vastaa siihen tietosuojalainsäädännön
              edellyttämässä määräajassa.
            </p>
            <p>
              Lisäksi rekisteröidyllä on oikeus peruuttaa antamansa suostumus,
              jos henkilötietojen käsittely on perustunut suostumukseen.
              Peruuttaminen ei kuitenkaan vaikuta ennen peruuttamista suoritetun
              käsittelyn lainmukaisuutteen.
            </p>

            <p>
              Lisäksi rekisteröidyllä on oikeus kieltää tietojen käyttäminen
              suoramarkkinointiin tai peruuttaa tätä tarkoitusta varten aiemmin
              annettu suostumus.
            </p>
            <p>
              Rekisteröidyllä on myös oikeus tehdä valitus
              valvontaviranomaiselle.
            </p>
            <p>
              Rekisteröidyn pyynnöt osoitetaan rekisterinpitäjälle kohdassa 1
              mainittuja yhteystietoja käyttäen.
            </p>
          </TextWrapper>
        </div>
      </section>
    </Layout>
  )
}

export default PrivacyPolicyPage
